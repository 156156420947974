import {createStore} from 'redux'
import {composeWithDevTools} from "redux-devtools-extension";

export type RootState = {
  sidebarShow: boolean
  sidebarUnfoldable: boolean
  currentBusiness: number | null
  model: {
    menu?: { list: any[] }
    currency?: { list: any[] }
    address?: { list: any[] }
  }
};

// const storedState = localStorage.getItem('s')? JSON.parse(localStorage.getItem('s')): {}
const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
  currentBusiness: null,
  model: {},
  // ...storedState
}

const modelReducers = {
  setAll: (state: RootState, params) => {
    let model = params.model
    let items = params.items

    let s = JSON.parse(JSON.stringify(state))
    s.model[model] = {
      ...s.model[model] ?? {},
      lastFetchAll: (new Date()).getTime(),
      list: items
    }

    return {...s}
  },
  setItems: (state: RootState, params) => {
    let model = params.model
    let items: any[] = params.items

    let s = JSON.parse(JSON.stringify(state))
    s.model[model] = {
      ...s.model[model] ?? {},
      list: s.model[model].list ?? []
    }
    for (const item of items) {
      let index = s.model[model].list.findIndex(x => x.id === item.id)
      if (index === -1) {
        s.model[model].list.push(item)
      } else {
        s.model[model].list[index] = item
      }
    }
    return {...s}
  },
  deleteItems: (state: RootState, params) => {
    let model = params.model
    let ids: any[] = params.ids

    let s = JSON.parse(JSON.stringify(state))
    s.model[model] = {
      ...s.model[model] ?? {},
      list: s.model[model].list ?? []
    }
    s.model[model].list = s.model[model].list.filter(x => !ids.includes(x.id))
    return {...s}
  },
}

const changeState = (state: RootState = initialState, {type, ...rest}) => {
  let newState
  switch (type) {
    case 'set':
      newState = {...state, ...rest}
      break
    case 'setModelList':
      newState = modelReducers.setAll(state, rest)
      break
    case 'setModelItems':
      newState = modelReducers.setItems(state, rest)
      break
    case 'deleteModelItems':
      newState = modelReducers.deleteItems(state, rest)
      break
    case 'clear':
      newState = initialState
      break
    default:
      newState = state
  }
  // localStorage.setItem('s', JSON.stringify(newState))
  return {...newState}
}


const store = createStore(changeState, composeWithDevTools())
export default store
